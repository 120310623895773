@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* hamburger menu button: https://css-tricks.com/line-animated-hamburger-menu/ */
.line {
  fill: none;
  stroke: currentColor;
  stroke-width: 6;
  transition: stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
}
.line2 {
  stroke-dasharray: 60 60;
}
.line3 {
  stroke-dasharray: 60 207;
}
.opened.line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}
.opened.line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}
.opened.line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

/* https://jarv.is/notes/css-waving-hand-emoji/ */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.667s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
