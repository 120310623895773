/* be-vietnam-pro-100 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 100;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-100.woff2") format("woff2");
}

/* be-vietnam-pro-200 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 200;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-200.woff2") format("woff2");
}

/* be-vietnam-pro-300 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 300;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-300.woff2") format("woff2");
}

/* be-vietnam-pro-regular - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-regular.woff2") format("woff2");
}

/* be-vietnam-pro-500 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-500.woff2") format("woff2");
}

/* be-vietnam-pro-600 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-600.woff2") format("woff2");
}

/* be-vietnam-pro-700 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-700.woff2") format("woff2");
}

/* be-vietnam-pro-700italic - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 700;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-700italic.woff2") format("woff2");
}

/* be-vietnam-pro-800 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 800;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-800.woff2") format("woff2");
}

/* be-vietnam-pro-900 - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 900;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-900.woff2") format("woff2");
}

/* be-vietnam-pro-800italic - vietnamese_latin */
@font-face {
  font-family: "Be Vietnam Pro";
  font-style: italic;
  font-weight: 800;
  font-display: optional;
  src: url("/fonts/be-vietnam-pro-v2-vietnamese_latin-800italic.woff2") format("woff2");
}
